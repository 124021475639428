import React, {Component} from 'react';
import { srgbToLinear, hexToRgb } from './lib/Colors';
import { TwitterPicker, CirclePicker} from 'react-color';
// import Popup from 'yarn add semantic-ui-react;
import { theme, animation, contextMenu  } from 'react-contexify';
import { Menu, Item, Separator, Submenu, MenuProvider } from 'react-contexify';
import { Button, Grid, Header, Popup } from 'semantic-ui-react';
import { linearToSrgb, linearToSrgb2, rgbToHex } from './lib/Colors';
//import { color2lib } from 'color2library';

const menuId = 'thisIsAnId';

const MyMenu = () => (
    <Menu id={menuId} theme={theme.dark} animation={animation.zoom}>
      <Item>Copy</Item>
      <Separator />
      <Item>Paste</Item>
      <Item>Cut</Item>
    </Menu>
    );
    
class Viewer extends Component {
    constructor(props) {
        super(props)
        this.state = { open: false }
        this.openModal = this.openModal.bind(this)
        this.closeModal = this.closeModal.bind(this)

        }
        
    openModal (){
    this.setState({ open: true })
    }
    closeModal () {
    this.setState({ open: false })
    }
      
	state = {
		materials: null,
		nodesData: null,
		sceneGraph: null,
		animationsData: null,
        api: null,
        initalMaterial: null,
        clickEvent: null,
        colors: null,
        clickedMat: null	
    }

    handleChangeComplete = (color) => {
        //this.setState({ background: color.hex });
        this.setState( { isOpen: false } );

        this.setMaterialColor(this.state.clickedMat, color.hex, this.state.clickedMat.channels.DiffusePBR.factor);
    };
    
    handleOpen = () => {
        this.setState({ isOpen: true });
    
        // this.timeout = setTimeout(() => {
        //   this.setState({ isOpen: false })
        // }, timeoutLength)
      }
    
      handleClose = () => {
        this.setState({ isOpen: false })
        clearTimeout(this.timeout)
      }

	copyMaterial(from, to){
		from.stateSetID = to.stateSetID;

		this.state.api.setMaterial(from, function () {
			console.log('Material updated');
		});
	}

    getMaterialByName(materialName) {
        if (!this.state.materials) {
            return null;
        }

        return this.state.materials.reduce(function(acc, cur) {
            if (cur.name === materialName) {
                return cur;
            }
            return acc;
        }, null);
    }

    setMaterialColor(material, hexColor, factor) {
        const linearColor = srgbToLinear(hexToRgb(hexColor));

        material.channels.AlbedoPBR.color = linearColor;
        material.channels.DiffusePBR.color = linearColor;
        material.channels.DiffuseColor.color = linearColor;
        material.channels.DiffuseColor.factor = factor || 1.0;
        material.channels.AlbedoPBR.texture = undefined;
        material.channels.DiffusePBR.texture = undefined;
        material.channels.DiffuseColor.texture = undefined;

        this.state.api.setMaterial(material, function(err, result) {
            if (err) {
                console.error(err);
            }
        });
    }   

    componentDidMount() {      
        var urlid = '9f8a7bd262e049899b03d6f439975b68';
        var client = new window.Sketchfab('1.4.2', this.iframe);
        var context = this;

        // const color2library = require("color2library");

        // color2library({
        //     libraries: ['pantone'],
        //     color: '#111111' 
        //   }, (colors) => {
        //     console.log(colors);
        //   })
		
        client.init(urlid, {
            camera: 0,
            preload: 1,
            ui_controls: 0,
            ui_infos: 0,
            ui_watermark: 0,
            animation_autoplay: 0,
            ui_stop: 0,
            transparent: 0,
            scrollwheel: 0,
            double_click: 0,
            orbit_constraint_pitch_down : 0.0,
            annotation : 0,
            annotations_visible : 0,
            //orbit_constraint_zoom_in: 14,
            //orbit_constraint_zoom_out: 50,
            //debug3d: 1,

            success: (api) => {
                api.start();
                api.addEventListener('viewerready', function() {
                    //api.hideAnnotation(0);
					//api.hideAnnotation(1);
					context.state.api = api;

                    //api.gotoAnnotation(0);

                    api.getNodeMap( function(error, nodes) {
						if (error) return console.log(error);
						
                        console.log(nodes);
                        context.state.nodesData = nodes;
                    });

                    api.getSceneGraph( function(error, result) {
                            if (error) return console.log(error);

                            context.state.sceneGraph = result;
                            console.log(result);
                    });

					api.getMaterialList(function (error, materials) {
						if (error) return console.log(error);

						//console.log(materials);
                        context.state.materials = materials;
                       
                        const initalMaterial = context.getMaterialByName("color");
                        context.state.initalMaterial = initalMaterial;
                        
                        const rgb = linearToSrgb(initalMaterial.channels.AlbedoPBR.color);

                        const defaultColor = '#'+ rgbToHex( rgb );

                        context.state.colors = new Map();

                        materials.forEach(element => {
                            if( element != context.state.initalMaterial ){                               
                                var rgb = linearToSrgb(element.channels.AlbedoPBR.color);

                                var hexColor = rgbToHex( rgb );                               
                                context.state.colors.set( element.name, { color: hexColor, factor: element.channels.AlbedoPBR.factor } );
                                //context.copyMaterial(context.state.initalMaterial, element);

                                context.setMaterialColor(element, defaultColor, element.channels.AlbedoPBR.factor);
                            }                          
                        });
                        
					});  
					
					api.addEventListener('click', function (e) {
						console.log(e.material);
						// context.setMaterialColor(e.material, 
                        //     '#'+Math.floor(Math.random()*16777215).toString(16), 1.0);
                        // let event = new MouseEvent("click", {
                        //     bubbles: true,
                        //     cancelable: true,
                        //     clientX: e.position2D[0],
                        //     clientY: e.position2D[1]
                        //   });
                        // contextMenu.show({
                        //     id: menuId,
                        //     event: event,
                        //     props: {
                        //       msg: 'hello'
                        //     } });
                        let found = context.state.colors.get(e.material.name);

                        const color2library = require("color2library");

                        color2library({
                            libraries: ['hks'],
                            color: '#' + found.color
                          }, (colors) => {
                            const m = colors.map(c => c.color).slice(0,4);
                            m.unshift('#' + found.color);
    
                            context.setState({ isOpen: true, x: e.position2D[0], y: e.position2D[1] , distColors: m, clickedMat: e.material });

                            console.log(colors);
                          });
                          

                        //var colors = window.PMSColorMatching(found.color, 48);                        

                        //const m = colors.map(c => '#' + window.PMS2RGB(c)).slice(0,4);
                       
                        //context.setState({ open: !context.state.open })

                    }, { pick: "slow" });
                    document.onclick = context.handleEvent;
                }); 
            },
            error: function onError() {
                console.log('Viewer error');
            }
        });        
    }

   
    handleEvent = e => {
        this.state.clickEvent = e;
      };

    render() {
        // const { x, y, show : menuShow } = this.x;
        const menuStyle = {
            top: this.state.y,
            left: this.state.x,
            position: "absolute"
          };
        return (  
            <div class="configurator-container">
                  <iframe ref={(iframe) => {
                this.iframe = iframe;
                    }} src="about:blank" title="Sketchfab Viewer" allowFullScreen mozallowfullscreen="true" webkitallowfullscreen="true" >
                </iframe>
                {/* <div class="overlay" ></div> */}
                {/* <MyMenu/> */}
                {/* <button className="button" onClick={this.openModal}>
                    Controlled Popup
                </button> */}
                <Popup
                    // trigger={<Button content='Open controlled popup' />}
                    content={<CirclePicker colors={this.state.distColors} onChangeComplete={ this.handleChangeComplete }/>}
                    on='click'
                    open={this.state.isOpen}
                    onClose={this.handleClose}
                    onOpen={this.handleOpen}
                    position='top left'
                    style={menuStyle}
                />
                {/* <Popup
                    open={this.state.open}
                    closeOnDocumentClick
                    onClose={this.closeModal}
                >
                    <TwitterPicker  />
                </Popup> */}
              
            </div>             
           
        );
    }
}

export default Viewer;